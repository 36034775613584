import { useEffect } from 'react';
import { DataFrame, PanelData } from '@grafana/data';
import { useDispatch } from 'react-redux';
import { findDataField, findInDataSource } from '@findData';
import { MachineState, MachineStateState, STATE_OPTIONS } from '@types';
import { lineDataActions } from '@store';

export type FieldData = { dataFieldName: string; stateUpdate: STATE_OPTIONS };

export interface UpdateMachineStateParams {
  panelData: PanelData | Omit<PanelData, 'timeRange'>;
  dataQueryName: string;
  enkNameField: string;
}

export function useUpdateMachineState(
  { panelData, dataQueryName, enkNameField }: UpdateMachineStateParams,
  ...fieldData: FieldData[]
): void {
  const dispatch = useDispatch();
  useEffect(() => {
    const foundData = findInDataSource(panelData, dataQueryName);
    if (foundData && foundData.length > 0) {
      const enkNames = findDataField(foundData, enkNameField)?.values.map((el) => el as string);
      if (enkNames) {
        const distinctStateUpdates = createMachineStateStateArrays(foundData, fieldData);
        const stateUpdates = reduceStateUpdates(distinctStateUpdates);
        const stateUpdateArray = createStateUpdateArrays(enkNames, stateUpdates);
        if (stateUpdateArray.length > 0) {
          dispatch(lineDataActions.updateMachineState(stateUpdateArray));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelData]);
}

export function createMachineStateStateArrays(foundData: DataFrame, fieldData: FieldData[]) {
  return fieldData.map((field, index) => {
    const dataField = findDataField(foundData, field.dataFieldName);
    if (dataField) {
      const update = dataField.values.map((value) => {
        return { [field.stateUpdate]: value } as MachineStateState;
      });
      return update;
    } else {
      return [];
    }
  });
}

export function reduceStateUpdates(stateUpdates: MachineStateState[][]) {
  return stateUpdates.reduce((accumulator, current) => {
    const mergedStates = accumulator.map((el, index) => {
      return { ...el, ...current[index] };
    });
    return mergedStates;
  });
}

export function createStateUpdateArrays(enkNameArray: string[], stateUpdates: MachineStateState[]) {
  return enkNameArray.map((name, index) => {
    return {
      enkName: name,
      state: stateUpdates[index],
    } as MachineState;
  });
}
