import React, { useState, useEffect } from 'react';
import { PanelData } from '@grafana/data';
import { findDataField } from '@findData';
import { machineStateOptions } from '@getMachineState';
import { Line, Subunit } from '@types';
import styles from './StatusLegend.module.css';
import df from 'd-forest';

interface StatusLegendProps {
  panelData: PanelData;
  line: Line | undefined;
  viewId: string | undefined;
}

export const StatusLegend: React.FC<StatusLegendProps> = ({ panelData, line, viewId }) => {
  const [currentStatuses, setCurrentStatuses] = useState<Array<{ name: string; color: string }>>([]);

  useEffect(() => {
    if (line && viewId && panelData && panelData.series.length > 0) {
      const currentView = line.views.find((v) => v.viewId === viewId);
      if (!currentView) {
        return;
      }

      const subunits = df.findLeaves<Subunit>(currentView.nodes, (subunit) => Object.keys(subunit).includes('enkName'));

      const enkNames = subunits.map((subunit) => subunit.enkName);

      const uniqueStatuses = new Set<number>();

      panelData.series.forEach((series) => {
        const machineIds = findDataField(series, 'machineid');
        const values = findDataField(series, 'state_datasource_value');
        if (machineIds && values) {
          const enkNamesSet = new Set(enkNames.map((enkName) => enkName.trim().toLowerCase()));

          machineIds.values.forEach((id, index) => {
            const trimmedMachineId = id.trim().toLowerCase();
            if (enkNamesSet.has(trimmedMachineId)) {
              uniqueStatuses.add(values.values[index]);
            }
          });
        }
      });

      const filteredStatuses = Array.from(uniqueStatuses)
        .map((status) => {
          const foundOption = machineStateOptions.find((option) => parseInt(option.path, 10) === status);
          return foundOption ? { name: foundOption.name, color: foundOption.defaultValue } : null;
        })
        .filter((status) => status !== null) as Array<{ name: string; color: string }>;

      setCurrentStatuses(filteredStatuses);
    }
  }, [panelData, line, viewId]);

  return (
    <div className={styles.container}>
      <div className={styles.statusContainer}>
        {currentStatuses.map((status, index) => (
          <div key={index} className={styles.statusElement} data-testid="status-element">
            <span className={styles.statusColor} style={{ backgroundColor: status.color }}></span>
            <span>{status.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
