export enum STATE_OPTIONS {
  state = 'state',
  timestamp = 'timestamp',
  currentTypeInfo = 'currentTypeInfo',
  pieces = 'pieces',
  nio = 'nio',
  currentCycleTime = 'currentCycleTime',
  refCycleTime = 'refCycleTime',
  lastUpdate = 'lastUpdate',
}

export interface MachineStateState {
  [STATE_OPTIONS.state]?: number;
  [STATE_OPTIONS.timestamp]?: number;
  [STATE_OPTIONS.currentTypeInfo]?: number;
  [STATE_OPTIONS.pieces]?: number;
  [STATE_OPTIONS.nio]?: number;
  [STATE_OPTIONS.currentCycleTime]?: number;
  [STATE_OPTIONS.refCycleTime]?: number;
  [STATE_OPTIONS.lastUpdate]?: number;
}

export interface MachineState {
  enkName: string;
  state: MachineStateState;
}

export interface LineDataState {
  machineState: MachineState[];
  isInitialLoad: boolean;
}
