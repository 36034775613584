// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YGG5E1NXEPNDFg4Hh0Ud {
  grid-row-start: 1;
  grid-row-end: -1;
  width: 100%;
  height: 100%;
}

.AF3jZlxrzcnHyR5pgqv9 {
  padding: 5px 10px;
  background: white;
  border: 1px solid black;
  border-radius: 5px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.sme3D6QABxnuY4kT_p3a > button {
  background-color: #22252b;
  border-color: #ccccdc26;
}

.sme3D6QABxnuY4kT_p3a > button:hover {
  background-color: #30343a;
}

.sme3D6QABxnuY4kT_p3a > button > svg {
  fill: rgba(204, 204, 220, 0.65);
}
`, "",{"version":3,"sources":["webpack://./../../../libs/viewer-component/src/lib/ViewerWindow/ViewerWindow.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,uBAAuB;EACvB,kBAAkB;;EAElB,mFAAmF;AACrF;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".viewer-main {\n  grid-row-start: 1;\n  grid-row-end: -1;\n  width: 100%;\n  height: 100%;\n}\n\n.react-flow__panel {\n  padding: 5px 10px;\n  background: white;\n  border: 1px solid black;\n  border-radius: 5px;\n\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;\n}\n\n.controls-dark > button {\n  background-color: #22252b;\n  border-color: #ccccdc26;\n}\n\n.controls-dark > button:hover {\n  background-color: #30343a;\n}\n\n.controls-dark > button > svg {\n  fill: rgba(204, 204, 220, 0.65);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewer-main": `YGG5E1NXEPNDFg4Hh0Ud`,
	"react-flow__panel": `AF3jZlxrzcnHyR5pgqv9`,
	"controls-dark": `sme3D6QABxnuY4kT_p3a`
};
export default ___CSS_LOADER_EXPORT___;
