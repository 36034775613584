// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drwHblBCF2NXwsgU9OrX {
  position: absolute;
  bottom: 0;
  left: 5%;
  right: 0;
  width: 95%;
  display: flex;
  justify-content: center;
  padding: 10px 2%;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
  border-radius: 5px;
}

.hUPvuzpd5Ryd7m52smI0 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 10px 0;
  box-sizing: border-box;
  width: 100%;
}

.byCMq2wP9AwxpvbOoYjd {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  line-height: normal;
  letter-spacing: 0.32px;
}

.u06w3B9Njxj95otOYpqp {
  display: inline-block;
  width: 1.5em;
  height: 1em;
  border-radius: 0.3em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #3f3;
}
`, "",{"version":3,"sources":["webpack://./components/StatusLegend.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,QAAQ;EACR,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,sBAAsB;EACtB,0CAA0C;EAC1C,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,SAAS;EACT,eAAe;EACf,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,WAAW;EACX,oBAAoB;EACpB,oCAAoC;EACpC,gBAAgB;AAClB","sourcesContent":[".container {\n  position: absolute;\n  bottom: 0;\n  left: 5%;\n  right: 0;\n  width: 95%;\n  display: flex;\n  justify-content: center;\n  padding: 10px 2%;\n  box-sizing: border-box;\n  background-color: rgba(255, 255, 255, 0.7);\n  z-index: 1000;\n  border-radius: 5px;\n}\n\n.statusContainer {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  gap: 20px;\n  padding: 10px 0;\n  box-sizing: border-box;\n  width: 100%;\n}\n\n.statusElement {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  font-family: Roboto, sans-serif;\n  font-size: 1rem;\n  font-weight: 700;\n  color: #000;\n  line-height: normal;\n  letter-spacing: 0.32px;\n}\n\n.statusColor {\n  display: inline-block;\n  width: 1.5em;\n  height: 1em;\n  border-radius: 0.3em;\n  border: 1px solid rgba(0, 0, 0, 0.2);\n  background: #3f3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `drwHblBCF2NXwsgU9OrX`,
	"statusContainer": `hUPvuzpd5Ryd7m52smI0`,
	"statusElement": `byCMq2wP9AwxpvbOoYjd`,
	"statusColor": `u06w3B9Njxj95otOYpqp`
};
export default ___CSS_LOADER_EXPORT___;
