// @ts-ignore
import React, { FC } from 'react';
import { machineStateOptions } from '@getMachineState';
import { PanelPlugin, PanelProps } from '@grafana/data';
import { Provider } from 'react-redux';
import { SimpleOptions } from '@types';
import { SimplePanel } from './components/SimplePanel';
import { store } from '@store';

type SimplePanelWrappedProps = PanelProps<SimpleOptions>;

const SimplePanelWrapped: FC<SimplePanelWrappedProps> = (props) => {
  return (
    <Provider store={store}>
      <SimplePanel {...props} />
    </Provider>
  );
};

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanelWrapped).setPanelOptions((builder) => {
  builder.addTextInput({
    name: 'Machine Link. Variable: ${__machine} / ${__machine:id} / ${__machine:displayname}',
    path: 'machineLink',
    defaultValue: '',
  });
  builder.addBooleanSwitch({ name: 'Linien Name', path: 'lineTitle', defaultValue: true });
  builder.addBooleanSwitch({ name: 'Minimap', path: 'miniMap', defaultValue: false });
  builder.addBooleanSwitch({ name: 'Zoom', path: 'activateZoom', defaultValue: false });
  builder.addBooleanSwitch({ name: 'Buchstaben zeigen', path: 'showLetters', defaultValue: false });
  builder.addBooleanSwitch({ name: 'ANDON', path: 'isAndonBoard', defaultValue: false });
  builder.addBooleanSwitch({ name: 'Duration', path: 'showDuration', defaultValue: false });
  builder.addBooleanSwitch({ name: 'Heartbeat', path: 'showHeartbeat', defaultValue: false });
  builder.addBooleanSwitch({ name: 'Production Warning', path: 'showProductionWarning', defaultValue: false });
  machineStateOptions.forEach((option) => {
    builder.addColorPicker(option);
  });
  return builder;
});
