// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.S4p3kyVpKhiBqTNonXjx {
  height: 100%;
  display: grid;
  grid-template-areas:
    'topbar'
    'viewer';
  grid-template-rows: 2em 1fr;
}
`, "",{"version":3,"sources":["webpack://./../../../libs/viewer-component/src/lib/Viewer/Viewer.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb;;YAEU;EACV,2BAA2B;AAC7B","sourcesContent":[".viewer {\n  height: 100%;\n  display: grid;\n  grid-template-areas:\n    'topbar'\n    'viewer';\n  grid-template-rows: 2em 1fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewer": `S4p3kyVpKhiBqTNonXjx`
};
export default ___CSS_LOADER_EXPORT___;
