import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Viewer.module.css';
import '../assets/fonts.css';
import '../assets/global-vars.css';
import ViewerWindow from '../ViewerWindow/ViewerWindow';
import { useActiveLineIdSetup } from '@hooks';
import { ThemeContextWrapper } from '@ThemeContextWrapper';
import { ViewerProps } from '@types';
import { PluginDataWrapper } from '@PluginDataWrapper';

export const Viewer: FC<ViewerProps> = ({ theme, pluginData }) => {
  const params = useParams();

  useActiveLineIdSetup(params.lineId || pluginData.line?.id);

  return (
    <PluginDataWrapper data={pluginData}>
      <ThemeContextWrapper theme={theme}>
        <div className={styles.viewer}>
          <ViewerWindow />
        </div>
      </ThemeContextWrapper>
    </PluginDataWrapper>
  );
};
