import React, { useEffect, useState } from 'react';
import { lineActions } from '@store';
import { useDispatch } from 'react-redux';
import { useVisibilityArray } from './useVisibilityArray';

export const useVisibilitySwitch = (nodeId: string) => {
  const dispatch = useDispatch();

  const visibilityArray = useVisibilityArray();
  const isVisible = visibilityArray.includes(nodeId);

  const [groupIsVisible, setGroupIsVisible] = useState(isVisible);
  useEffect(() => {
    setGroupIsVisible(isVisible);
  }, [isVisible]);

  const updateVisibility = (id: string) => {
    setGroupIsVisible((prev) => !prev);
    dispatch(lineActions.updateGroupVisibilityArray(id));
  };

  return { groupIsVisible, updateVisibility };
};
