import { dateTimeFormatWithAbbrevation, UrlQueryMap } from '@grafana/data';
import { locationService } from '@grafana/runtime';


/**
 * This formats a time field to ensure the millisecond portion of the epoch time is
 * included.
 *
 * @param time the epoch time to format
 *
 * @returns the formatted epoch time containing milliseconds.
 */
export const getEpochWithMillis = (time: number): number => {
  return time < 100000000000 ? time * 1000 : time;
};

/**
 * This formats a time field to ensure the millisecond portion of the epoch time is
 * NOT included.
 *
 * @param time the epoch time to format
 *
 * @returns the formatted epoch time containing milliseconds.
 */
export const getEpochWithoutMillis = (time: number): number => {
  return time > 100000000000 ? time / 1000 : time;
};

/**
 * This changes the Query map of the URL to effectively change the to and from time.
 *
 * @param time_from the start time
 * @param time_to the end time. Defaults to 'now' if null or invalid.
 *
 **/
export function changeTimeRangeAndRefresh(
  time_from: number,
  time_to?: number,
  defaultRefreshRate?: string
) {
  const queryParams = locationService.getSearchObject();

  // Start with the time parameters
  let queryMap: UrlQueryMap = { from: getEpochWithMillis(time_from), to: 'now' };

  if (time_to && !isNaN(time_to)) {
    queryMap.to = getEpochWithMillis(time_to);
  }

  if (queryParams.hasOwnProperty('refresh') || defaultRefreshRate) {
    // If refresh is present in the URL, store it and use it in a separate call.
    const refresh = queryParams.refresh || defaultRefreshRate;
    queryMap.refresh = refresh;
    setTimeout(() => locationService.partial({refresh}, true), 0);
    // add it after something below 5 seconds again, because that's the default most of the time
    // getTimeSrv is not implemented yet in react panels as of 2024-01-23 v10.1
    setTimeout(() => locationService.partial({refresh}, true), 3000);
  }

  locationService.partial(queryMap, true);
}
/**
 * Get an Epoch date in a human readble format.
 *
 * @param time the epoch time. Can be in seconds or milliseconds
 *
 * @returns a srting containing the human readbale date.
 */
export const getPrettyDate = (time?: number): string => {
  if (typeof time !== 'undefined' && time !== null && !isNaN(time)) {
    return dateTimeFormatWithAbbrevation(getEpochWithMillis(time));
  }
  return 'now';
};
