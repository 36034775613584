import { Line } from '@types';
import { RootState } from '@store';
import { useSelector } from 'react-redux';
import { initialNewLine } from '@initialData';

export const useActiveLine = (): Line => {
  const lines = useSelector((state: RootState) => state.lines.lines);
  const activeLineId = useSelector((state: RootState) => state.lines.activeLineId);

  if (activeLineId) {
    const activeLine = lines.find((line) => line.id === activeLineId);
    if (activeLine) {
      return activeLine;
    }
  }
  return initialNewLine;
};
