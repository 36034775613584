import React, { Component } from 'react';

import './css/TimepickerButton.css';
import { TimepickerData } from './types';
import {changeTimeRangeAndRefresh, getEpochWithoutMillis, getPrettyDate} from './utils';
import { Select } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';

interface TimepickerSelectProps {
  timepickerData: TimepickerData[];
  currentTimeFrom: number;
  currentTimeTo: number;
  defaultIndex: number;
  defaultRefreshRate: string;
}

export class TimepickerSelect extends Component<TimepickerSelectProps> {

  state = {
    value: null as SelectableValue<number> | null,
  };

  setValue = (value: SelectableValue<number>) => {
    this.setState({ value });
  };

  getCurrentValue = () => {
    return this.state.value;
  };

  componentDidMount() {
    const index = this.props.timepickerData.findIndex(v => {
      return getEpochWithoutMillis(v.time_from) === this.props.currentTimeFrom &&
        getEpochWithoutMillis(v.time_to??0) === this.props.currentTimeTo;
    });
    if(index >= 0) {
      this.setValue(index as unknown as SelectableValue<number>);
    } else if (this.props.timepickerData.length >= (this.props.defaultIndex + 1)) {
      this.setValue(this.props.defaultIndex as unknown as SelectableValue<number>);
    }
  }

  handleChange = (selectedOption: SelectableValue) => {
    const selected: TimepickerData = this.props.timepickerData[selectedOption.value];
    this.setValue(selectedOption.value);
    changeTimeRangeAndRefresh(selected.time_from, selected.time_to);
  };

  getOptions = () => {
    return this.props.timepickerData.map((option, index) => ({
      label: option.errors.length > 0 ? 'Error' : option.text || getPrettyDate(option.time_from),
      value: index,
      description: option.errors.join() || getPrettyDate(option.time_from) + ' to ' + getPrettyDate(option.time_to),
    }));
  };

  render() {
    return (
      <Select
        onChange={this.handleChange}
        value={this.getCurrentValue()}
        options={this.getOptions()}
      />
    );
  }
}
