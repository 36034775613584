import CustomEdge from './Nodes/CustomEdge/CustomEdge';
import HandManufacturingCN from './Nodes/CustomNodes/HandManufacturingCN';
import MachineGroupDoubleBufferCN from './Nodes/CustomNodes/MachineGroupDoubleBufferCN';
import MachineGroupDoubleCN from './Nodes/CustomNodes/MachineGroupDoubleCN';
import MachineGroupDoubleStackedCN from './Nodes/CustomNodes/MachineGroupDoubleStackedCN';
import MachineGroupQuadStackedCN from './Nodes/CustomNodes/MachineGroupQuadStackedCN';
import MachineGroupSingleBufferCN from './Nodes/CustomNodes/MachineGroupSingleBufferCN';
import MachineGroupSingleCN from './Nodes/CustomNodes/MachineGroupSingleCN';
import MachineGroupSixStackedCN from './Nodes/CustomNodes/MachineGroupSixStackedCN';
import MachineGroupTripleBufferCN from './Nodes/CustomNodes/MachineGroupTripleBufferCN';
import MachineGroupTripleCN from './Nodes/CustomNodes/MachineGroupTripleCN';
import Landmark from './Nodes/Landmark/Landmark';

export const customNodeTypes = {
  mgSingle: MachineGroupSingleCN,
  mgSingleBuffer: MachineGroupSingleBufferCN,
  mgDouble: MachineGroupDoubleCN,
  mgDoubleStacked: MachineGroupDoubleStackedCN,
  mgDoubleBuffer: MachineGroupDoubleBufferCN,
  mgTriple: MachineGroupTripleCN,
  mgTripleBuffer: MachineGroupTripleBufferCN,
  mgSixStacked: MachineGroupSixStackedCN,
  mgQuadStacked: MachineGroupQuadStackedCN,
  manualHandManufacturing: HandManufacturingCN,
  landmark: Landmark,
};

export const customEdgeTypes = {
  custom: CustomEdge,
};
