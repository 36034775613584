import { ThemeContext } from '@sharedContext';
import { useContext } from 'react';
import { useIsPlugin } from './useIsPlugin';

export const useDarkThemeMachineGroup = () => {
  const isPlugin = useIsPlugin();
  const theme: any = useContext(ThemeContext);
  return isPlugin
    ? theme.isDark
      ? {
          fill: theme.colors.background.secondary,
          stroke: theme.colors.border.strong,
        }
      : {}
    : {};
};
