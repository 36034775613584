import { DataFrame, PanelData } from '@grafana/data';

export function findInDataSource(
  data: PanelData | Omit<PanelData, 'timeRange'>,
  searchFor: string
): DataFrame | undefined {
  return data.series.find((source) => source.refId?.endsWith(searchFor));
}

export function findDataField(data: DataFrame, searchFor: string) {
  return data.fields.find((field) => field.name === searchFor);
}
