import React, { FC } from 'react';
import { GroupHandleProps } from '@types';
import { GroupHandlesBottom } from './GroupHandlesBottom';
import { GroupHandlesLeft } from './GroupHandlesLeft';
import { GroupHandlesRight } from './GroupHandlesRight';
import { GroupHandlesTop } from './GroupHandlesTop';

const GroupHandles: FC<GroupHandleProps> = (props) => {
  const { groupWidth } = props;
  return (
    <>
      <GroupHandlesTop groupWidth={groupWidth} />
      <GroupHandlesRight groupWidth={groupWidth} />
      <GroupHandlesBottom groupWidth={groupWidth} bottomHandleLeft={props.bottomHandleLeft} />
      <GroupHandlesLeft groupWidth={groupWidth} />
    </>
  );
};

export default GroupHandles;
