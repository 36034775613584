import React, { FC } from 'react';
import styles from '../MachineGroups.module.css';
import { BufferProps } from '@types';

const Buffer: FC<BufferProps> = (props) => {
  return (
    <svg
      width="80"
      height="60"
      x={props.x}
      y={props.y}
      className={`${styles.buffer} buffer`}
      data-id={`buffer-${props.bufferId}`}
      data-buffer-id={`${props.nodeId}-b-${props.bufferId}`}
      data-node-id={props.nodeId}
    >
      <rect x="2" y="2" width="76" height="56" rx="3" className={styles['buffer-rect']} />
      <text className={styles['buffer-text']}>
        <tspan x="9" y="9" className={styles['buffer-name']}>
          {props.buffer.displayName}
        </tspan>
        <tspan x="40" y="27">
          Stk. / Platz
        </tspan>
        <tspan x="40" y="39" data-buffer-capacity={`${props.nodeId}-b-${props.bufferId}`}>
          {props.buffer.current ?? '..'} {' / '} {props.buffer.capacity}
        </tspan>
      </text>
    </svg>
  );
};

export default Buffer;
