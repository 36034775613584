// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.c_nx03d4VW9SFwmIxJha {
  max-width: 400px;
  width: 100%;
  text-align: center;
  padding: 20px;
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.qLAB1ThFceVBdGTDX5GF {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ff6347;
}

.R_bbytC5lpZ0UyfIiTNG {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
}

.n114o9XNGiLDjBph0VHi {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ff6347;
  color: #fff;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./components/ProductionWarning.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,4CAA4C;EAC5C,yBAAyB;EACzB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;AACd;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".warningContainer {\n  max-width: 400px;\n  width: 100%;\n  text-align: center;\n  padding: 20px;\n  border: 1px solid black;\n  border-radius: 8px;\n  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);\n  background-color: #ffffff;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 999;\n}\n\n.warningHeading {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 10px;\n  color: #ff6347;\n}\n\n.warningMessage {\n  font-size: 18px;\n  color: #555;\n  margin-bottom: 20px;\n}\n\n.warningIcon {\n  display: inline-block;\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  background-color: #ff6347;\n  color: #fff;\n  font-size: 24px;\n  line-height: 40px;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningContainer": `c_nx03d4VW9SFwmIxJha`,
	"warningHeading": `qLAB1ThFceVBdGTDX5GF`,
	"warningMessage": `R_bbytC5lpZ0UyfIiTNG`,
	"warningIcon": `n114o9XNGiLDjBph0VHi`
};
export default ___CSS_LOADER_EXPORT___;
