interface MachineStateOption {
  path: string;
  name: string;
  defaultValue: string;
}

export const machineStateOptions: MachineStateOption[] = [
  {
    path: '8',
    name: 'Technische Störung',
    defaultValue: '#ff0000',
  },
  {
    path: '16',
    name: 'Techn. Störung wird behoben',
    defaultValue: '#0000ff',
  },
  {
    path: '32',
    name: 'Organisatorische Störung',
    defaultValue: '#aa142d',
  },
  {
    path: '64',
    name: 'Org. Störung wird behoben',
    defaultValue: '#6682a4',
  },
  {
    path: '0', // TBD
    name: 'Undefinierter Status',
    defaultValue: '#6d7579',
  },
  {
    path: '99991', // TBD
    name: 'Keine Kommunikation',
    defaultValue: '#434c53',
  },
  {
    path: '256',
    name: 'Maschine ein',
    defaultValue: '#d5d9d8',
  },
  {
    path: '512',
    name: 'Maschine aus',
    defaultValue: '#b0b6b8',
  },
  {
    path: '99992', // TBD
    name: 'Pause',
    defaultValue: '#cace98',
  },
  {
    path: '128',
    name: 'Manueller Werkzeugwechsel',
    defaultValue: '#ffffff',
  },
  {
    path: '1024',
    name: 'Umrüsten / Typwechsel',
    defaultValue: '#ffffff',
  },
  {
    path: '99993', // TBD
    name: 'TPM',
    defaultValue: '#6c4859',
  },
  {
    path: '1',
    name: 'Produktion',
    defaultValue: '#00ff00',
  },
  {
    path: '4096',
    name: 'Produktion mit abgewählter Funktion',
    defaultValue: '#00a500',
  },
  {
    path: '16384',
    name: 'Produktion mit 0% Override',
    defaultValue: '#00a500',
  },
  {
    path: '32768',
    name: 'Produktion ohne PSS (Reservebit 15)',
    defaultValue: '#00a500',
  },
  {
    path: '2048',
    name: 'Zyklischer Taktzeitverlust',
    defaultValue: '#00b88a',
  },
  {
    path: '2',
    name: 'Warten auf Teil',
    defaultValue: '#ffd671',
  },
  {
    path: '4',
    name: 'Auslauf belegt',
    defaultValue: '#ffff00',
  },
  {
    path: '8192',
    name: 'Standby',
    defaultValue: '#7ac9b9',
  },
  {
    path: '99994', // TBD
    name: 'Nicht beplant',
    defaultValue: '#715d49',
  },
];

export interface MachineStateLetterOption {
  state: number;
  letter: string;
  andonOnly?: boolean;
}

export const machineStateLetters: MachineStateLetterOption[] = [
  {
    state: 128, // Manueller Werkzeugwechsel
    letter: 'W',
    andonOnly: false,
  },
  {
    state: 1024, // Umrüsten / Typwechsel
    letter: 'U',
    andonOnly: false,
  },
  {
    state: 4096, // Produktion mit abgewählter Funktion
    letter: 'A',
    andonOnly: false,
  },
  {
    state: 16384, // Produktion mit 0% Override
    letter: 'O',
    andonOnly: false,
  },
  {
    state: 32768, // Produktion ohne PSS (Reservebit 15)
    letter: 'S',
    andonOnly: false,
  },
  {
    state: 2048, // Zyklischer Taktzeitverlust
    letter: 'Z',
    andonOnly: true,
  },
  {
    state: 2, // Warten auf Teil
    letter: 'W',
    andonOnly: true,
  },
  {
    state: 4, // Auslauf belegt
    letter: 'A',
    andonOnly: true,
  },
  {
    state: 8192, // Standby
    letter: 'S',
    andonOnly: true,
  },
];
