import { ThemeContext } from '@sharedContext';
import React, { ReactNode } from 'react';

/* eslint-disable-next-line */
export interface ThemeContextWrapperProps {
  children?: ReactNode;
  theme?: unknown;
}

export const ThemeContextWrapper = (props: ThemeContextWrapperProps) => {
  return <ThemeContext.Provider value={props.theme as any}>{props.children}</ThemeContext.Provider>;
};
