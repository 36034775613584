import React, { FC } from 'react';
import { UnitProps } from '@types';
import Machine from '../Machine/Machine';
import Filter from '../Filter';
import styles from '../MachineGroups.module.css';
import { useDarkThemeMachineGroup } from '@hooks';

export const MachineGroupSixStacked: FC<UnitProps> = (props) => {
  return (
    <svg
      width="280"
      height="170"
      className={`${styles.group} group`}
      data-id={`group`}
      data-node-id={props.machineGroup.id}
      data-active-node={props.machineGroup.id ? true : false}
      data-test-id="MachineGroupSixStacked"
      data-group-id={props.machineGroup.id}
    >
      <Filter />
      <rect
        x="1"
        y="1"
        width="278"
        height="168"
        rx="5"
        className={styles['group-outline']}
        style={useDarkThemeMachineGroup()}
      />
      <text x="12" y="10" className={styles['group-text']}>
        {props.machineGroup.displayName}
      </text>
      <Machine
        machine={props.machineGroup.subunits[0]}
        x={10}
        y={30}
        machineId={0}
        nodeId={`${props.machineGroup.id}`}
      />
      <Machine
        machine={props.machineGroup.subunits[1]}
        x={100}
        y={30}
        machineId={1}
        nodeId={`${props.machineGroup.id}`}
      />
      <Machine
        machine={props.machineGroup.subunits[2]}
        x={190}
        y={30}
        machineId={2}
        nodeId={`${props.machineGroup.id}`}
      />
      <Machine
        machine={props.machineGroup.subunits[3]}
        x={10}
        y={96}
        machineId={3}
        nodeId={`${props.machineGroup.id}`}
      />
      <Machine
        machine={props.machineGroup.subunits[4]}
        x={100}
        y={96}
        machineId={4}
        nodeId={`${props.machineGroup.id}`}
      />
      <Machine
        machine={props.machineGroup.subunits[5]}
        x={190}
        y={96}
        machineId={5}
        nodeId={`${props.machineGroup.id}`}
      />
    </svg>
  );
};
