import { MachineState } from '@types';
import { useIsPlugin } from './useIsPlugin';
import { useLineData } from './useLineData';
import { sanitizeMachineId } from '@utils';

export function useMachineState(enkName: string): MachineState {
  const undefinedMachineState: MachineState = {
    enkName: enkName,
    state: {
      state: 0,
    },
  };
  const isPlugin = useIsPlugin();
  const machineStateArray = useLineData().machineState;

  if (isPlugin) {
    const machineState = machineStateArray.find((state: MachineState) => state.enkName === sanitizeMachineId(enkName));
    return machineState ? machineState : undefinedMachineState;
  }

  return undefinedMachineState;
}
