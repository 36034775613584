import { Line, UnitNode } from '@types';

export function sanitizeMachineId(enkName: string) {
  return enkName.endsWith('/') ? enkName : `${enkName}/`;
}

function updateEnkNamesInNodeArray(nodes: UnitNode[]) {
  nodes.forEach((node) => {
    const { machineGroup } = node.data;
    machineGroup.subunits.forEach((subunit) => {
      subunit.enkName = sanitizeMachineId(subunit.enkName);
    });

    if (machineGroup.handManufacturing) {
      machineGroup.handManufacturing.enkName = sanitizeMachineId(machineGroup.handManufacturing.enkName);
    }
  });
}

export function verifyEnkNames(updatedLine: Line): Line {
  updateEnkNamesInNodeArray(updatedLine.nodes);
  updatedLine.views.forEach((view) => {
    updateEnkNamesInNodeArray(view.nodes);
  });
  return updatedLine;
}
