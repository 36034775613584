import React, { useState } from 'react';
import styles from './ProductionWarning.module.css';

interface ProductionWarningProps {
  showProductionWarning: boolean;
}

const ProductionWarning: React.FC<ProductionWarningProps> = ({ showProductionWarning }) => {
  const [showWarning, setShowWarning] = useState(true);

  return (
    <div style={{ fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif' }}>
      {showProductionWarning && showWarning && (
        <div className={styles.warningContainer}>
          <div className={styles.warningIcon}>X</div>
          <div className={styles.warningHeading}>Warning!</div>
          <div className={styles.warningMessage}>There is no production at this moment!</div>
        </div>
      )}
    </div>
  );
};

export { ProductionWarning };
