import { machineStateOptions, machineStateLetters } from './machinestate-options';

export const getMachineStateName = (
  state: number | undefined
): { path: string; name: string; defaultValue: string } => {
  const undefinedState = machineStateOptions.find((el) => el.path === '0') || machineStateOptions[4];
  if (state) {
    const stateColor = machineStateOptions.find((el) => el.path === state.toString());
    if (stateColor) return stateColor;
    return undefinedState;
  }
  return undefinedState;
};

export const getMachineStateLetterOption = (state: number | undefined) => {
  return machineStateLetters.find((el) => el.state === state);
};
