import { initialNodes } from './initialNodes';
import { initialEdges } from './initialEdges';
import { initialViewport } from './initialViewport';
import { Line } from '@types';

export const initialLines: Line[] = [
  {
    factory: '26',
    type: 'F',
    domain: 'R4KW03',
    displayName: 'G/P4-52 Kurbelwelle Otto EA888 Linie 3',
    id: '3iIMIRppnxj',
    nodes: initialNodes,
    edges: initialEdges,
    viewport: initialViewport,
    views: [
      {
        viewId: '3iIMIRppnxj-0',
        viewTitle: 'G/P4-52 KW Otto EA888 L3 Ansicht 1',
        nodes: initialNodes,
        edges: initialEdges,
        viewport: initialViewport,
      },
    ],
  },
];

export const initialNewLine: Line = {
  factory: '',
  type: '',
  domain: '',
  displayName: '',
  id: '',
  nodes: [],
  edges: [],
  viewport: undefined,
  views: [],
};
