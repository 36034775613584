import { configureStore } from '@reduxjs/toolkit';
import { linesReducer } from './lines';
import { appDataReducer } from './appData';
import { lineDataReducer } from './lineData';

export const store = configureStore({
  reducer: { appData: appDataReducer, lines: linesReducer, lineData: lineDataReducer },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
