import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { de } from 'date-fns/locale';
import { formatDistanceToNowStrict } from 'date-fns';
import { getMachineStateName } from '@getMachineState';
import { ThemeContext } from '@sharedContext';
import { TooltipPortal } from '@tooltipPortal';
import { useIsPlugin, useLineData } from '@hooks';
import { usePopper } from 'react-popper';
import cssStyles from './Tooltip.module.css';
import { sanitizeMachineId } from '@utils';

/* eslint-disable-next-line */
export interface TooltipProps {
  referenceElement: SVGElement | undefined | null;
  data: {
    displayName: string;
    enkName: string;
  };
}

enum NOVALUE {
  NoValue = '--',
}

interface TooltipData {
  state: string | NOVALUE.NoValue;
  timestamp: number | NOVALUE.NoValue;
  currentTypeInfo: number | NOVALUE.NoValue;
  pieces: number | NOVALUE.NoValue;
  nio: number | NOVALUE.NoValue;
  currentCycleTime: number | NOVALUE.NoValue;
  refCycleTime: number | NOVALUE.NoValue;
  lastUpdate: number | NOVALUE.NoValue;
}

export const Tooltip: FC<TooltipProps> = (props) => {
  const data = props.data;

  const [referenceElement] = useState<SVGElement | null | undefined>(props.referenceElement);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 50 } },
      { name: 'offset', options: { offset: [90, 5] } },
    ],
    placement: 'auto',
  });

  const lineData = useLineData().machineState;

  const [tooltipData, setTooltipData] = useState<TooltipData>({
    state: NOVALUE.NoValue,
    timestamp: NOVALUE.NoValue,
    currentTypeInfo: NOVALUE.NoValue,
    pieces: NOVALUE.NoValue,
    nio: NOVALUE.NoValue,
    currentCycleTime: NOVALUE.NoValue,
    refCycleTime: NOVALUE.NoValue,
    lastUpdate: NOVALUE.NoValue,
  });

  const isPlugin = useIsPlugin();

  useEffect(() => {
    if (isPlugin && lineData) {
      const machineState = lineData.find((el) => el.enkName === `${sanitizeMachineId(data.enkName)}`);

      setTooltipData((prev) => {
        return {
          ...prev,
          state: getMachineStateName(machineState?.state.state).name
            ? `${getMachineStateName(machineState?.state.state).name}`
            : tooltipData.state,
          timestamp: machineState?.state.timestamp ?? tooltipData.timestamp,
          currentTypeInfo: machineState?.state.currentTypeInfo ?? tooltipData.currentTypeInfo,
          pieces: machineState?.state.pieces ?? tooltipData.pieces,
          nio: machineState?.state.nio ?? tooltipData.nio,
          currentCycleTime: machineState?.state.currentCycleTime ?? tooltipData.currentCycleTime,
          refCycleTime: machineState?.state.refCycleTime ?? tooltipData.refCycleTime,
          lastUpdate: machineState?.state.lastUpdate ?? tooltipData.lastUpdate,
        };
      });
    }
  }, [isPlugin, data, lineData, tooltipData]);

  const theme: any = useContext(ThemeContext);

  const tooltipContainerStyle = () => {
    const setStyle = () => {
      if (theme.isDark) {
        return {
          ...styles.popper,
          backgroundColor: theme.colors.background.primary,
          borderColor: theme.colors.border.medium,
        };
      }
      return { ...styles.popper };
    };
    return isPlugin ? setStyle() : { ...styles.popper };
  };

  const tooltipContentStyle = () => {
    const setStyle = () => {
      if (theme.isDark) {
        return {
          backgroundColor: theme.colors.background.secondary,
          borderColor: theme.colors.border.weak,
        };
      }
      return {};
    };
    return isPlugin ? setStyle() : {};
  };

  return (
    <TooltipPortal>
      <div
        className={cssStyles['tooltip-container']}
        ref={setPopperElement}
        style={tooltipContainerStyle()}
        {...attributes.popper}
      >
        <div className={cssStyles['tooltip-content']}>
          <div className={cssStyles['tooltip-content__name']} style={tooltipContentStyle()}>
            {data.displayName}
          </div>
          <div className={cssStyles['tooltip-content__info']}>
            Status: <span>{tooltipData.state}</span>
          </div>
          <div className={cssStyles['tooltip-content__info']}>
            Seit: &nbsp;
            <span>
              {typeof tooltipData.timestamp === 'number'
                ? formatDistanceToNowStrict(tooltipData.timestamp, { locale: de })
                : NOVALUE.NoValue}
            </span>
          </div>
          <div className={cssStyles['tooltip-content__info']}>
            Aktueller Typ: <span>{tooltipData.currentTypeInfo}</span>
          </div>
          <div className={cssStyles['tooltip-content__info']}>
            Gesamt:{' '}
            <span>
              {tooltipData.pieces} {tooltipData.pieces !== NOVALUE.NoValue ? '[Stk.]' : ''}
            </span>
          </div>
          <div className={cssStyles['tooltip-content__info']}>
            NIO:{' '}
            <span>
              {tooltipData.nio} {tooltipData.nio !== NOVALUE.NoValue ? '[Stk.]' : ''}
            </span>
          </div>
          <div className={cssStyles['tooltip-content__info']}>
            Ist-Taktzeit:{' '}
            <span>
              {tooltipData.currentCycleTime} {tooltipData.currentCycleTime !== NOVALUE.NoValue ? '[s]' : ''}
            </span>
          </div>
          <div className={cssStyles['tooltip-content__info']}>
            Soll-Taktzeit:{' '}
            <span>
              {tooltipData.refCycleTime} {tooltipData.refCycleTime !== NOVALUE.NoValue ? '[s]' : ''}
            </span>
          </div>
          <div className={cssStyles['tooltip-content__info']}>
            Letzte Aktualisierung:{' '}
            <span>
              {typeof tooltipData.lastUpdate === 'number'
                ? formatDistanceToNowStrict(tooltipData.lastUpdate, { locale: de })
                : NOVALUE.NoValue}
            </span>
          </div>
        </div>
        {/* <div className={cssStyles['tooltip-arrow']} ref={setArrowElement} style={styles.arrow} data-popper-arrow /> */}
      </div>
    </TooltipPortal>
  );
};
