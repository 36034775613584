import { PluginData } from '@types';
import React, { FC, ReactNode, createContext } from 'react';

export interface PluginDataWrapperProps {
  children: ReactNode;
  data: PluginData;
}

export const PluginDataContext = createContext<PluginData>({});

export const PluginDataWrapper: FC<PluginDataWrapperProps> = ({ children, data }) => {
  return <PluginDataContext.Provider value={data}>{children}</PluginDataContext.Provider>;
};

export default PluginDataWrapper;
