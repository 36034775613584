import React, { FC } from 'react';

const Filter: FC = () => {
  return (
    <defs>
      <filter id="selectedMachine">
        <feGaussianBlur result="blur" stdDeviation="3" />
        <feMerge>
          <feMergeNode in="blur" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
  );
};

export default Filter;
