import { createSlice } from '@reduxjs/toolkit';

export interface Overlay {
  newLine?: boolean;
  deleteLine?: boolean;
  deleteView?: boolean;
  noSelectedNodes?: boolean;
}

export interface AppDataState {
  editorIsActive: boolean;
  lineSelected?: boolean;
  overlay: Overlay;
  isPlugin: boolean;
  editViews: boolean;
  enkMatch: boolean;
  reorderView: boolean;
  hasChanges: boolean;
}

const initialData: AppDataState = {
  editorIsActive: false,
  lineSelected: false,
  overlay: {
    newLine: false,
    deleteLine: false,
    deleteView: false,
    noSelectedNodes: false,
  },
  isPlugin: false,
  editViews: false,
  enkMatch: false,
  reorderView: false,
  hasChanges: false,
};

const appDataSlice = createSlice({
  name: 'appdata',
  initialState: initialData,
  reducers: {
    setEditorIsActive(state, action: { payload: boolean }) {
      state.editorIsActive = action.payload;
    },

    setLineIsSelected(state, action: { payload: boolean }) {
      state.lineSelected = action.payload;
    },

    setOverlayActive(state, action: { payload: Overlay }) {
      const key = Object.keys(action.payload)[0] as keyof Overlay;
      const value = Object.values(action.payload)[0] as boolean;
      state.overlay[key] = value;
    },

    setIsPlugin(state, action: { payload: boolean }) {
      state.isPlugin = action.payload;
    },

    setEditViews(state, action: { payload: boolean }) {
      state.editViews = action.payload;
    },

    setEnkMatch(state, action: { payload: boolean }) {
      state.enkMatch = action.payload;
    },

    setReorderView(state, action: { payload: boolean }) {
      state.reorderView = action.payload;
    },

    setHasChanges(state, action: { payload: boolean }) {
      state.hasChanges = action.payload;
    },
  },
});

export const appDataActions = appDataSlice.actions;
export const appDataReducer = appDataSlice.reducer;
