import { Line, View } from '@types';
import * as _ from 'lodash';

export function setMachineMultiplier(line: Line): Line {
  line.nodes.forEach((node) => {
    node.data.machineGroup.subunits.forEach((subunit) => {
      subunit.multiplier = subunit.multiplier ?? 1;
    });
  });

  line.views.forEach((view) => {
    view.nodes.forEach((node) => {
      node.data.machineGroup.subunits.forEach((subunit) => {
        const mainNode = line.nodes.find((mNode) => mNode.data.machineGroup.id === node.data.machineGroup.id);
        if (mainNode) {
          const mainSubunit = mainNode.data.machineGroup.subunits.find((mComp) => mComp.enkName === subunit.enkName);
          if (mainSubunit) {
            subunit.multiplier = mainSubunit.multiplier ?? 1;
          }
        }
      });
    });
  });

  return line;
}

export function setNewViewId(views: View[]) {
  if (views.length > 0) {
    const lastView = views[views.length - 1];
    const newViewId = +lastView.viewId.split('-')[1] + 1;
    return newViewId;
  }
  return 0;
}

export function setUpdatedPropertiesInViews(line: Line): Line {
  line.views.forEach((view) => {
    view.nodes.forEach((viewNode) => {
      const lineNode = line.nodes.find((node) => node.id === viewNode.id);
      if (lineNode) {
        const updatedMachineGroup = _.cloneDeep(lineNode.data.machineGroup);
        viewNode.data.machineGroup = updatedMachineGroup;
      }
    });
  });
  return line;
}
