import React, { FC } from 'react';
import { HandManufacturingProps } from '@types';
import Filter from '../Filter';
import styles from '../MachineGroups.module.css';

export const HandManufacturing: FC<HandManufacturingProps> = (props) => {
  const size = 36;
  return (
    <svg
      width={size}
      height={size}
      className={`${styles['hand']}`}
      data-id={`hand`}
      data-hand-id={props.data.id}
      data-node-id={props.data.id}
      data-active-node={props.data.id ? true : false}
      data-test-id="HandManufacturing"
      data-group-id={props.data.id}
    >
      <Filter />
      <circle cx={size / 2} cy={size / 2} r={size / 2 - 2} className={styles['hand-manufacturing']} />
    </svg>
  );
};
