// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wESQVQZBUHm7mfzRcEa1 {
  opacity: 0;
}

.P3wEQRrVEXODmrr6UETb {
  bottom: 0;
  transform: translate(-50%, 0);
}

.gOH4z8eGW9vVhkIcy2Tn {
  margin: 0;
  padding: 0;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./../../../libs/custom-nodes/src/lib/GroupHandles/GroupHandles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,6BAA6B;AAC/B;;AAEA;EACE,SAAS;EACT,UAAU;EACV,UAAU;AACZ","sourcesContent":[".hidden-handle {\n  opacity: 0;\n}\n\n.bottom-handle {\n  bottom: 0;\n  transform: translate(-50%, 0);\n}\n\n.handle {\n  margin: 0;\n  padding: 0;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden-handle": `wESQVQZBUHm7mfzRcEa1`,
	"bottom-handle": `P3wEQRrVEXODmrr6UETb`,
	"handle": `gOH4z8eGW9vVhkIcy2Tn`
};
export default ___CSS_LOADER_EXPORT___;
