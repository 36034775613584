import React, { FC } from 'react';
import { createPortal } from 'react-dom';

export interface TooltipPortalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}

export const TooltipPortal: FC<TooltipPortalProps> = ({ children }) => {
  return createPortal(children, document.body);
};
