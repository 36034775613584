// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes HnFHDetzX2tZZ7K23Xx3 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.AZQj_ix8HdE0GQCn_DPn {
  min-width: 180px;
  border: 1px solid black;
  border-radius: 9px;
  background-color: #ffffffee;
  animation: HnFHDetzX2tZZ7K23Xx3 160ms;
  z-index: 99999;
}

.DeW5yjT3sMsomL41guPR > * {
  padding: 0 0.5rem;
}

.WQ22OexZWNoU8y1isWQY {
  font-weight: 700;
  padding: 0.5rem;
  margin-bottom: 0.5em;
  background-color: lightgray;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  border-bottom: 1px solid black;
}

.NApl5uTcDh_HRowc3hgJ {
  font-size: 0.9em;
}

.NApl5uTcDh_HRowc3hgJ:last-of-type {
  padding-bottom: 0.8em;
}

.ailVui_stzMwINsjiV8L .O9IvrOooml0omtVgZA5n[data-popper-arrow] {
  aspect-ratio: 1 / 1;
  width: 16px;
  left: -8px;
  border: 1px solid black;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./../../../libs/tooltip/src/lib/Tooltip.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,kBAAkB;EAClB,2BAA2B;EAC3B,qCAAwB;EACxB,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,oBAAoB;EACpB,2BAA2B;EAC3B,4BAA4B;EAC5B,2BAA2B;EAC3B,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,UAAU;EACV,uBAAuB;EACvB,uBAAuB;AACzB","sourcesContent":["@keyframes tooltip {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n.tooltip-container {\n  min-width: 180px;\n  border: 1px solid black;\n  border-radius: 9px;\n  background-color: #ffffffee;\n  animation: tooltip 160ms;\n  z-index: 99999;\n}\n\n.tooltip-content > * {\n  padding: 0 0.5rem;\n}\n\n.tooltip-content__name {\n  font-weight: 700;\n  padding: 0.5rem;\n  margin-bottom: 0.5em;\n  background-color: lightgray;\n  border-top-right-radius: 9px;\n  border-top-left-radius: 9px;\n  border-bottom: 1px solid black;\n}\n\n.tooltip-content__info {\n  font-size: 0.9em;\n}\n\n.tooltip-content__info:last-of-type {\n  padding-bottom: 0.8em;\n}\n\n.too .tooltip-arrow[data-popper-arrow] {\n  aspect-ratio: 1 / 1;\n  width: 16px;\n  left: -8px;\n  border: 1px solid black;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip-container": `AZQj_ix8HdE0GQCn_DPn`,
	"tooltip": `HnFHDetzX2tZZ7K23Xx3`,
	"tooltip-content": `DeW5yjT3sMsomL41guPR`,
	"tooltip-content__name": `WQ22OexZWNoU8y1isWQY`,
	"tooltip-content__info": `NApl5uTcDh_HRowc3hgJ`,
	"too": `ailVui_stzMwINsjiV8L`,
	"tooltip-arrow": `O9IvrOooml0omtVgZA5n`
};
export default ___CSS_LOADER_EXPORT___;
