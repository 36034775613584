import React, { FC, memo } from 'react';
import { NodeProps } from '@xyflow/react';
import { MachineGroupDoubleStacked } from '../MachineGroups/MachineGroupDoubleStacked';
import GroupHandles from '../../GroupHandles/GroupHandles';
import { CustomNodeToolbar } from '../../CustomNodeToolbar/CustomNodeToolbar';
import { useViewsEditor, useVisibilitySwitch } from '@hooks';
import { UnitNode } from '@types';

const MachineGroupDoubleStackedCN: FC<NodeProps<UnitNode>> = (props) => {
  const { id, data } = props;
  const { groupIsVisible, updateVisibility } = useVisibilitySwitch(id);
  const viewsEditorActive = useViewsEditor();

  return (
    <>
      {viewsEditorActive && (
        <CustomNodeToolbar {...props} groupIsVisible={groupIsVisible} updateVisibility={updateVisibility} />
      )}
      <GroupHandles groupWidth={100} />
      <MachineGroupDoubleStacked machineGroup={data.machineGroup} />
    </>
  );
};

export default memo(MachineGroupDoubleStackedCN);
