import React, { useEffect, useRef, useState } from 'react';
import { Line, STATE_OPTIONS, SimpleOptions, View } from '@types';
import { lineActions, lineDataActions } from '@store';
import { DataFrameView, PanelData, PanelProps } from '@grafana/data';
import { useDispatch } from 'react-redux';
import { useLineData, useSetIsPlugin } from '@hooks';
import { useTheme2 } from '@grafana/ui';
import { Viewer } from '@viewerComponent';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io/dist/typed-events';
import { getTemplateSrv } from '@grafana/runtime';
import _ from 'lodash';
import { verifyUrlInput, verifyDashboardUrl } from '../utils/url-utils';
import { findDataField, findInDataSource } from '@findData';
import { useUpdateMachineState } from '@grafanaHooks';
import { ProductionWarning } from './ProductionWarning';
import { StatusLegend } from './StatusLegend';

interface Props extends PanelProps<SimpleOptions> {}
type SimplePanelProps = Omit<Props, 'timeRange' | 'eventBus'>;

export const SimplePanel: React.FC<SimplePanelProps> = (props) => {
  const { options, data, width, height } = props;

  function interpolateMachineLink(machineLink: string): string {
    let url = verifyUrlInput(machineLink);
    const templateSrv = getTemplateSrv();
    if (templateSrv.containsTemplate(url)) {
      url = templateSrv.replace(url);
    }
    const verifiedDashboardUrl = verifyDashboardUrl(url);
    return verifiedDashboardUrl;
  }

  const updatedOptions = _.cloneDeep(options);
  updatedOptions.machineLink = interpolateMachineLink(options.machineLink);

  useSetIsPlugin(true);
  const theme = useTheme2();
  const [panelData, setPanelData] = useState<PanelData>(data);
  const [line, setLine] = useState<Line>();
  const [viewId, setViewId] = useState<string>();
  const [streamOptions, setStreamOptions] = useState<string>();
  const socketIOConnection = useRef<Socket<DefaultEventsMap, DefaultEventsMap>>();
  const [showWarning, setShowWarning] = useState<boolean>(false);

  const dispatch = useDispatch();
  const lineDataState = useLineData();

  useEffect(() => {
    const lineFromDatasource = findInDataSource(data, 'bde-line-jsondata');
    if (lineFromDatasource) {
      const lineField = findDataField(lineFromDatasource, 'line');
      if (lineField) {
        const line = JSON.parse(lineField.values[0]) as Line;
        setLine(line);
      }

      const selectedViewField = findDataField(lineFromDatasource, 'selected_view');
      if (selectedViewField) {
        const selectedView = JSON.parse(selectedViewField.values[0]) as View;
        setViewId(selectedView.viewId);
      }
    }
  }, [data]);

  useEffect(() => {
    dispatch(lineActions.setLines([line]));
  }, [line, dispatch]);

  useEffect(() => {
    if (viewId) {
      dispatch(lineActions.setLineView({ viewId }));
    }
  }, [viewId, dispatch]);

  useUpdateMachineState(
    {
      panelData: data,
      dataQueryName: 'bde-line-state-datasource',
      enkNameField: 'machineid',
    },
    {
      dataFieldName: 'state_datasource_value',
      stateUpdate: STATE_OPTIONS.state,
    },
    {
      dataFieldName: 'time',
      stateUpdate: STATE_OPTIONS.timestamp,
    }
  );

  useUpdateMachineState(
    {
      panelData: data,
      dataQueryName: 'pieces',
      enkNameField: 'machineid',
    },
    {
      dataFieldName: 'piecedata',
      stateUpdate: STATE_OPTIONS.pieces,
    }
  );

  useUpdateMachineState(
    {
      panelData: data,
      dataQueryName: 'current_cycle_time',
      enkNameField: 'machineid',
    },
    {
      dataFieldName: 'adjusted_value',
      stateUpdate: STATE_OPTIONS.currentCycleTime,
    }
  );

  useUpdateMachineState(
    {
      panelData: data,
      dataQueryName: 'nio',
      enkNameField: 'machineid',
    },
    {
      dataFieldName: 'rejected_piece_data',
      stateUpdate: STATE_OPTIONS.nio,
    }
  );

  useUpdateMachineState(
    {
      panelData: data,
      dataQueryName: 'reference_cycle_time',
      enkNameField: 'machineid',
    },
    {
      dataFieldName: 'adjusted_value',
      stateUpdate: STATE_OPTIONS.refCycleTime,
    }
  );

  useUpdateMachineState(
    {
      panelData: data,
      dataQueryName: 'last_update',
      enkNameField: 'machineid',
    },
    {
      dataFieldName: 'last_timestamp',
      stateUpdate: STATE_OPTIONS.lastUpdate,
    }
  );

  useUpdateMachineState(
    {
      panelData: data,
      dataQueryName: 'current_type',
      enkNameField: 'machineid',
    },
    {
      dataFieldName: 'current_type_value',
      stateUpdate: STATE_OPTIONS.currentTypeInfo,
    }
  );

  useEffect(() => {
    const isProductionUp =
      panelData &&
      panelData.series.length > 0 &&
      panelData.series.some((series) => {
        const machineStatusField = findDataField(series, 'state_datasource_value');
        if (machineStatusField) {
          const machineStatusValues = [...machineStatusField.values];
          return machineStatusValues.includes(1);
        }
        return false;
      });

    setShowWarning(!isProductionUp);
  }, [panelData]);

  // WS Stream
  useEffect(() => {
    function getRowByName(data: PanelData, name: string): any {
      const rows = data.series.map((frame) => {
        const dataFrameView = new DataFrameView(frame);
        const r = dataFrameView.toArray().filter((row) => {
          return row.name === name;
        });
        return r?.[0];
      });
      return rows[0];
    }

    function getValueByName(data: PanelData, name: string): any {
      return getRowByName(data, name)?.value;
    }

    function getStreamingOptions(data: PanelData): string {
      return getValueByName(data, 'stream');
    }

    setPanelData(data);

    const lineDataSource = data.series.find((el) => el.refId?.endsWith('bde-line-datasource'));
    if (lineDataSource) {
      const streamOptions = getStreamingOptions(data);
      if (streamOptions && !socketIOConnection.current) {
        setStreamOptions(streamOptions);
        socketIOConnection.current = io(streamOptions);
        socketIOConnection.current.on('machinestate:received', (data: any) => {
          dispatch(
            lineDataActions.updateMachineState([
              {
                enkName: data[0].machineid,
                state: {
                  state: data[0].value,
                  timestamp: new Date(data[0].time).getTime(),
                },
              },
            ])
          );
        });
      }
    }
  }, [data, dispatch, streamOptions]);

  useEffect(() => {
    return () => {
      socketIOConnection.current?.close();
    };
  }, [socketIOConnection]);

  return (
    <>
      <div style={{ width: width, height: height }}>
        {options.showProductionWarning && showWarning && (
          <ProductionWarning showProductionWarning={options.showProductionWarning} />
        )}
        <Viewer
          pluginData={{
            line: line,
            lineData: lineDataState,
            panelData: panelData,
            options: updatedOptions,
          }}
          theme={theme}
        />
        <StatusLegend panelData={panelData} line={line} viewId={viewId} />
      </div>
    </>
  );
};
