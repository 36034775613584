// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* variables here */
  --primary-color: #546e7a;
  --primary-light: #819ca9;
  --primary-dark: #29434e;
  --primary-text: #ffffff;
  --primary-text-light: #000000;
  --primary-text-dark: #ffffff;
  --secondary-color: #ffa726;
  --secondary-light: #ffd95b;
  --secondary-dark: #c77800;
  --secondary-text: #000000;
  --secondary-text-light: #000000;
  --secondary-text-dark: #000000;
  --chart-background-color: hsla(220, 24%, 15%, 0.2);
  --chart-background-color-edit: hsla(66, 66%, 18%, 0.6);
  --machine-group-background-color: hsla(220, 24%, 15%, 0.4);

  --mdc-typography-font-family: Tahoma;
  --mdc-theme-primary: var(--primary-color);
  --mdc-theme-on-primary: var(--primary-text);

  --min-height: 820px;
  --header-height: 64px;
  --shift-header-height: 34px;

  --lumo-primary-color: var(--primary-dark);

  --bde-typo-font-family: 'Nunito';
  --border-color: #5d5d5d;
  --selected-color: #202d33;
  --machine-group-text-color: #3f7d80;
  --buffer-details-text-color: #4275c2;

  --btn-primary-color: #0090d9;
  --btn-primary-hover-color: #00aaff;
  --btn-abort-color: #b61010;
  --btn-abort-hover-color: #d31515;
  --background-color: #424242ab;
  --btn-import-color: #279168;
  --btn-import-hover-color: #20ba7f;
}
`, "",{"version":3,"sources":["webpack://./../../../libs/viewer-component/src/lib/assets/global-vars.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,wBAAwB;EACxB,wBAAwB;EACxB,uBAAuB;EACvB,uBAAuB;EACvB,6BAA6B;EAC7B,4BAA4B;EAC5B,0BAA0B;EAC1B,0BAA0B;EAC1B,yBAAyB;EACzB,yBAAyB;EACzB,+BAA+B;EAC/B,8BAA8B;EAC9B,kDAAkD;EAClD,sDAAsD;EACtD,0DAA0D;;EAE1D,oCAAoC;EACpC,yCAAyC;EACzC,2CAA2C;;EAE3C,mBAAmB;EACnB,qBAAqB;EACrB,2BAA2B;;EAE3B,yCAAyC;;EAEzC,gCAAgC;EAChC,uBAAuB;EACvB,yBAAyB;EACzB,mCAAmC;EACnC,oCAAoC;;EAEpC,4BAA4B;EAC5B,kCAAkC;EAClC,0BAA0B;EAC1B,gCAAgC;EAChC,6BAA6B;EAC7B,2BAA2B;EAC3B,iCAAiC;AACnC","sourcesContent":[":root {\n  /* variables here */\n  --primary-color: #546e7a;\n  --primary-light: #819ca9;\n  --primary-dark: #29434e;\n  --primary-text: #ffffff;\n  --primary-text-light: #000000;\n  --primary-text-dark: #ffffff;\n  --secondary-color: #ffa726;\n  --secondary-light: #ffd95b;\n  --secondary-dark: #c77800;\n  --secondary-text: #000000;\n  --secondary-text-light: #000000;\n  --secondary-text-dark: #000000;\n  --chart-background-color: hsla(220, 24%, 15%, 0.2);\n  --chart-background-color-edit: hsla(66, 66%, 18%, 0.6);\n  --machine-group-background-color: hsla(220, 24%, 15%, 0.4);\n\n  --mdc-typography-font-family: Tahoma;\n  --mdc-theme-primary: var(--primary-color);\n  --mdc-theme-on-primary: var(--primary-text);\n\n  --min-height: 820px;\n  --header-height: 64px;\n  --shift-header-height: 34px;\n\n  --lumo-primary-color: var(--primary-dark);\n\n  --bde-typo-font-family: 'Nunito';\n  --border-color: #5d5d5d;\n  --selected-color: #202d33;\n  --machine-group-text-color: #3f7d80;\n  --buffer-details-text-color: #4275c2;\n\n  --btn-primary-color: #0090d9;\n  --btn-primary-hover-color: #00aaff;\n  --btn-abort-color: #b61010;\n  --btn-abort-hover-color: #d31515;\n  --background-color: #424242ab;\n  --btn-import-color: #279168;\n  --btn-import-hover-color: #20ba7f;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
