import { useState, useEffect } from 'react';
import { STATE_OPTIONS } from '@types';
import { useMachineState } from '@hooks';
import { formatDuration } from '@utils';

export function useFormattedDuration(machineName: string) {
  const machineState = useMachineState(machineName);
  const currTimeStamp = machineState.state[STATE_OPTIONS.timestamp];
  const [formattedTime, setFormattedTime] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFormattedTime(formatDuration(currTimeStamp));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [currTimeStamp]);

  return formattedTime;
}
