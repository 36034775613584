import React, { FC, memo } from 'react';
import { NodeProps } from '@xyflow/react';
import { HandManufacturing } from '../HandManufacturing/HandManufacturing';
import { CustomNodeToolbar } from '../../CustomNodeToolbar/CustomNodeToolbar';
import { useViewsEditor, useVisibilitySwitch } from '@hooks';
import { UnitNode } from '@types';

const HandManufacturingCN: FC<NodeProps<UnitNode>> = (props) => {
  const { id, data } = props;
  const { groupIsVisible, updateVisibility } = useVisibilitySwitch(id);
  const viewsEditorActive = useViewsEditor();

  return (
    <>
      {viewsEditorActive && (
        <CustomNodeToolbar {...props} groupIsVisible={groupIsVisible} updateVisibility={updateVisibility} />
      )}
      <HandManufacturing data={data.machineGroup} />
    </>
  );
};

export default memo(HandManufacturingCN);
