import React, { useState, useEffect } from 'react';
import { PanelData } from '@grafana/data';
import { findDataField } from '@findData';

interface ConnectionStatusProps {
  machineId: string;
  panelData: PanelData;
  showHeartbeat: boolean;
}

export const ConnectionStatus: React.FC<ConnectionStatusProps> = ({ machineId, panelData, showHeartbeat }) => {
  const [statusColor, setStatusColor] = useState<string>('red');

  useEffect(() => {
    if (panelData && panelData.series.length > 0) {
      panelData.series.forEach((series) => {
        const machineidField = findDataField(series, 'machineid');
        const colornumField = findDataField(series, 'colornum');

        if (machineidField && colornumField) {
          const machineidValues = machineidField.values;
          const colornumValues = colornumField.values;

          for (let i = 0; i < machineidValues.length; i++) {
            if (machineidValues[i] === machineId) {
              setStatusColor(colornumValues[i] === 1 ? 'green' : 'red');
              break;
            }
          }
        }
      });
    }
  }, [panelData, machineId]);

  const borderColor = statusColor === 'green' ? '#006400' : '#8B0000';

  if (!showHeartbeat) {
    return null;
  }

  return (
    <circle cx="70" cy="10" r="8" fill={statusColor} stroke={borderColor} strokeWidth="2" data-testid="status-circle" />
  );
};
