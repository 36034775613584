export function verifyUrlInput(url: string): string {
  try {
    new URL(url);
    return convertToRelativeUrl(url);
  } catch (error) {
    return url;
  }
}

export function convertToRelativeUrl(url: string): string {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url;
  }
  const parsedUrl = new URL(url);
  const relativeUrl = parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
  return relativeUrl;
}

/**
 *
 * @param relativeUrl string
 * @returns verified relative grafana dashboard url starting with '/d/'
 */
export function verifyDashboardUrl(relativeUrl: string): string {
  if (relativeUrl === '' || !relativeUrl) {
    return '';
  }
  if (!relativeUrl.startsWith('/d/')) {
    relativeUrl = relativeUrl.replace(/^(d\/)?(\/)?/, '/d/');
  }
  return relativeUrl;
}
