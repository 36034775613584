import React, { FC } from 'react';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { NodeProps, NodeToolbar } from '@xyflow/react';
import { useViewsEditor } from '@hooks';

interface CustomNodeToolbarProps extends NodeProps {
  groupIsVisible: boolean;
  updateVisibility: (id: string) => void;
}

export const CustomNodeToolbar: FC<CustomNodeToolbarProps> = (props) => {
  const isViewsEditor = useViewsEditor();

  return (
    <NodeToolbar isVisible={isViewsEditor}>
      <button
        className="icon-btn-show-group"
        data-view-button-id={`view-button-id-${props.id}`}
        onClick={() => {
          props.updateVisibility(props.id);
        }}
      >
        {props.groupIsVisible ? (
          <VisibilityOutlined
            fontSize="small"
            className="icon-btn-is-visible"
            data-is-visible-icon={props.groupIsVisible}
          />
        ) : (
          <VisibilityOffOutlined
            fontSize="small"
            className="icon-btn-is-invisible"
            data-is-invisible-icon={!props.groupIsVisible}
          />
        )}
      </button>
    </NodeToolbar>
  );
};
