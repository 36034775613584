import { createSlice } from '@reduxjs/toolkit';
import { LineDataState, MachineState } from '@types';

const initialLineData: LineDataState = {
  machineState: [],
  isInitialLoad: true,
};

const lineDataSlice = createSlice({
  name: 'linedata',
  initialState: initialLineData,
  reducers: {
    updateMachineState: (state, action: { payload: MachineState[] }) => {
      let newMachineStates: MachineState[] = [...state.machineState];

      // region helper functions
      const mergeMachineState = (
        { machineState }: LineDataState,
        index: number,
        stateUpdate: MachineState
      ): MachineState[] => {
        const retVal = machineState;
        const updatedMachineState = {
          ...retVal[index],
        };

        updatedMachineState.state = {
          ...updatedMachineState.state,
          ...stateUpdate.state,
        };

        retVal[index] = updatedMachineState;
        return retVal;
      };

      const containsStateForEnkName = ({ machineState }: LineDataState, { enkName }: MachineState): number => {
        return machineState.findIndex((state) => state.enkName === enkName);
      };

      const isNewer = (
        currentMachineState: MachineState | undefined,
        updatedMachineState: MachineState | undefined
      ) => {
        return (
          (currentMachineState?.state?.timestamp ?? 0) < (updatedMachineState?.state?.timestamp ?? 0) ||
          (currentMachineState?.state?.timestamp ?? 0) === (updatedMachineState?.state?.timestamp ?? 0)
        );
      };
      // endregion

      action.payload.forEach((el: MachineState) => {
        const index = containsStateForEnkName(state, el);
        if (index === -1) {
          newMachineStates.push(el);
        } else {
          if (isNewer(state.machineState[index], el)) {
            newMachineStates = mergeMachineState(state, index, el);
          } else if (!el.state.timestamp) {
            // added path, if there is no timestamp in the message
            newMachineStates = mergeMachineState(state, index, el);
          }
        }
      });

      newMachineStates.sort((a: MachineState, b: MachineState) => a.enkName.localeCompare(b.enkName));
      state.machineState = newMachineStates;
    },

    setInitialLoadFalse: (state) => {
      state.isInitialLoad = false;
    },
  },
});

export const lineDataActions = lineDataSlice.actions;
export const lineDataReducer = lineDataSlice.reducer;
