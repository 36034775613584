import React, { FC } from 'react';
import { GroupHandleProps } from '@types';
import { Handle, Position } from '@xyflow/react';
import { useEditorIsActive } from '@hooks';
import styles from './GroupHandles.module.css';
import { handleOffset, isHiddenTop } from './groupHandles-config';

export const GroupHandlesTop: FC<GroupHandleProps> = (props) => {
  const { groupWidth } = props;
  const editorIsActive = useEditorIsActive();

  const positionHandleLeft = {
    left: groupWidth / 2 - handleOffset,
  };

  const positionHandleRight = {
    left: groupWidth / 2 + handleOffset,
  };

  return (
    <>
      {/* // !OUTPUTS */}
      <Handle
        type="source"
        position={Position.Top}
        id={`output-top-left`}
        isConnectable={editorIsActive}
        style={editorIsActive ? positionHandleLeft : isHiddenTop}
        className={editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`}
      />

      <Handle
        type="source"
        position={Position.Top}
        id={`output-top`}
        isConnectable={editorIsActive}
        style={editorIsActive ? {} : isHiddenTop}
        className={editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`}
      />

      <Handle
        type="source"
        position={Position.Top}
        id={`output-top-right`}
        isConnectable={editorIsActive}
        style={editorIsActive ? positionHandleRight : isHiddenTop}
        className={editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`}
      />

      {/* // !INPUTS */}
      <Handle
        type="target"
        position={Position.Top}
        id={`input-top-left`}
        isConnectable={editorIsActive}
        style={editorIsActive ? positionHandleLeft : isHiddenTop}
        className={editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`}
      />

      <Handle
        type="target"
        position={Position.Top}
        id={`input-top`}
        isConnectable={editorIsActive}
        style={editorIsActive ? {} : isHiddenTop}
        className={editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`}
      />

      <Handle
        type="target"
        position={Position.Top}
        id={`input-top-right`}
        isConnectable={editorIsActive}
        style={editorIsActive ? positionHandleRight : isHiddenTop}
        className={editorIsActive ? `${styles['handle']}` : `${styles['hidden-handle']}`}
      />
    </>
  );
};
