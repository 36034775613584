import { lineActions } from '@store';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useActiveLineIdSetup = (lineId: string | undefined) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(lineActions.setActiveLineId(lineId));
  }, [dispatch, lineId]);
};
