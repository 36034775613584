/* istanbul ignore next */
(function () {
  if (!globalThis.process) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalThis.process = {
      env: {},
    };
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalThis.process = window.process = {
    ...globalThis.process,
    env: {
      ...globalThis.process.env,
      APP_VERSION: globalThis.process.env?.APP_VERSION || '',
    },
  };

  if (!globalThis.process.env.APP_VERSION) {
    try {
      globalThis.process.env.APP_VERSION = process.env.APP_VERSION;
    } catch (e) {
      console.log(e);
    }
  }
})();

export const environment = {
  production: true,
  host_url: '',
  websocket_url: '',
  app_version: globalThis.process.env?.APP_VERSION || '',
};
