import { environment } from '@environments';
import { ApiLogBodyParams } from '@types';
import axios, { AxiosResponse } from 'axios';

export async function logStoreError(message: string, error: unknown) {
  console.error(message, error);

  try {
    const response = await axios.post<any, AxiosResponse, ApiLogBodyParams>(`${environment.host_url}/api/log`, {
      logLevel: 'error',
      message: `${message} Error: ${error}`,
    });
    if (response.status === 200) {
      return 'OK';
    }
    return 'NOT OK';
  } catch (error) {
    console.error('Error sending error log', error);
    return 'NOT OK';
  }
}
