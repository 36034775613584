import React, { FC } from 'react';
import { EdgeProps, getSmoothStepPath } from '@xyflow/react';

const CustomEdge: FC<EdgeProps> = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition }) => {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });
  return <path id={id} d={edgePath} className="react-flow__edge-path" style={{ strokeWidth: '6px' }} />;
};

export default CustomEdge;
